export const MainURL = {
  // HostUrl: 'https://bigdadygames.com:6066',

  //local

  HostUrl: 'http://97.74.91.174:4849',

    // HostUrl: 'http://192.168.0.17:2585',
// 
  // HostUrl: 'http://a5c2a5edf4e8.ngrok.io',
  imageUrl: '/assets'
}
